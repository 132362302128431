import Vue from "vue";
import { Module } from "vuex";

import { api } from "@/util/axios";
import { PaymentInfo, Page } from "@/types";

type PaymentInfoState = {
  paymentInfoPage: Page<PaymentInfo>;
};

const paymentInfo: Module<PaymentInfoState, unknown> = {
  namespaced: true,
  state: () => ({
    paymentInfoPage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
  }),
  getters: {
    getPaymentInfo: (state) => (paymentInfoId: string) =>
      state.paymentInfoPage.docs.find((c) => c._id === paymentInfoId),
    paymentInfoPage: (state) => state.paymentInfoPage,
  },
  mutations: {
    ADD_PAYMENT_INFO: (state, _paymentInfo) => {
      let idx = -1;
      state.paymentInfoPage.docs.map((pi, i) => {
        if (pi._id === _paymentInfo._id) idx = i;
      });
      if (idx === -1) state.paymentInfoPage.docs.push(_paymentInfo);
      else Vue.set(state.paymentInfoPage.docs, idx, _paymentInfo);
    },
    SET_PAYMENT_INFO_PAGE: (state, list) => {
      state.paymentInfoPage = list;
    },
    REMOVE_PAYMENT_INFO(state, paymentInfo) {
      let idx = -1;
      state.paymentInfoPage.docs.map((pi, i) => {
        if (pi._id === paymentInfo._id) idx = i;
      });
      if (idx > -1) state.paymentInfoPage.docs.splice(idx, 1);
    },
  },
  actions: {
    fetchPaymentInfo(context, params = "") {
      api
        .get(`/v1/payment-info${params}`)
        .then((response) => {
          context.commit("ADD_PAYMENT_INFO", response.data.paymentInfo);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    fetchPaymentInfoList(context, params = "") {
      api
        .get(`/v1/payment-info${params}`)
        .then((response) => {
          context.commit(
            "SET_PAYMENT_INFO_PAGE",
            response.data.paymentInfoPage
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async createPaymentInfo(context, payload) {
      return await api
        .post(`/v1/payment-info`, payload)
        .then((response) => {
          context.commit("ADD_PAYMENT_INFO", response.data.paymentInfo);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Payment Info created",
            },
            { root: true }
          );
          return response.data.paymentInfo;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    updatePaymentInfo(context, data: { id: string; paymentInfo: PaymentInfo }) {
      api
        .put(`/v1/payment-info/${data.id}`, data.paymentInfo)
        .then((response) => {
          context.commit("ADD_PAYMENT_INFO", response.data.paymentInfo);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Payment Info updated",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    deletePaymentInfo(context, id) {
      api
        .delete(`/v1/payment-info${id}`)
        .then((response) => {
          context.commit("REMOVE_PAYMENT_INFO", response.data.paymentInfo);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Payment Info deleted",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default paymentInfo;

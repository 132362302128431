






































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

import paymentInfoStoreModule from "@/store/modules/paymentInfo";

const { mapActions: paymentInfoActions } =
  createNamespacedHelpers("PAYMENT_INFO");
export default Vue.extend<any, any, any, any>({
  name: "PaymentInfo",
  props: {
    userId: {
      type: String,
      required: true,
    },
    businessId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    valid: false,
    paymentTypes: ["card"],
    paymentType: "",
    paymentTypeRules: [(v: string) => !!v || "Payment is required"],
    cardNumber: "",
    cardNumberRules: [(v: string) => !!v || "Card number is required"],
    name: "",
    nameRules: [(v: string) => !!v || "Name is required"],
    expiryDate: "",
    expiryDateRules: [(v: string) => !!v || "Expiry Date is required"],
    cvv: "",
    cvvRules: [(v: string) => !!v || "CVV is required"],
  }),
  methods: {
    ...paymentInfoActions(["createPaymentInfo"]),
    validatePaymebtInfo() {
      const valid = (
        this.$refs.paymentInfoForm as Element & { validate: () => boolean }
      )?.validate();

      if (valid) {
        this.createPaymentInfo({
          userId: this.userId,
          cardNumber: this.cardNumber,
          cvv: this.cvv,
          nameOnCard: this.name,
          paymentMethod: this.paymentType,
        }).then((info) => {
          if (info)
            this.$router.push({
              path: `/sign-up/business/${this.userId}/${this.businessId}`,
            });
        });
      }
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("PAYMENT_INFO")) {
      this.$store.registerModule("PAYMENT_INFO", paymentInfoStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("PAYMENT_INFO");
  },
});
